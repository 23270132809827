import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import styles from './privacy-request.module.scss';

export default ({ data }) => {
  return (
    <Layout>
      <div className={'columns'}>
        <div className={`${styles.container}`}>
          <div className={`${styles.contentbox}`}>
            <h1 className={`title`}>
              Request to delete your data from Trustaff
            </h1>
            <p className={`content`}>
              You have the option of requesting the information Trustaff has
              collected about you be deleted at any time. If you request your
              information be deleted and you have a Trustaff Profile, you may
              not be able to recover your account after your information is
              deleted.
            </p>
            <p className={`content`}>
              If you currently have a Trustaff profile, please provide the same
              email you used when creating the account, but you do not need to
              have a Nurse Profile to submit a request.
            </p>
            <p className={`content`}>
              <strong>
                Please send an email to{' '}
                <a href="mailto:privacy@Trustaff.com">Privacy@Trustaff.com</a>{' '}
                to initate the deletion process as outlined by our{' '}
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
